'use strict'

import common from 'assets/themes/campings/js/page/common/index'
import ElementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'
import { Events } from 'assets/core/js/module/searchAutocomplete'
import SearchResult from '@ui/Search/Result/component'

import type { Calendar as SearchCalendarType } from '@ui/Search/Calendar/component'
import type SidePanel from '@campings-group/design-system/src/design/objects/sidePanel/twig/assets'
import { openMap } from 'assets/themes/campings/js/page/search/map'

const calendarId = 'search_offers_checkInDate'

const setAutocompleteProductId = (id: string | null, name: string | null): void => {
  const calendar = ElementPropertiesManager.getProperty<SearchCalendarType>(document.getElementById(calendarId) as HTMLInputElement, 'calendar')

  if (!id || !name || !calendar) {
    return
  }

  const autocompleteEl = document.getElementById('search_offers_autocomplete_autocomplete')

  if (!autocompleteEl) {
    return
  }

  const eventData = {
    token: '',
    name,
    nameHighlight: '',
    description: '',
    detail: {
      id: parseInt(id, 10),
      category: 'product',
      source: 'product.click',
    },
  }

  const fn = (): void => {
    if (calendar.input.value !== '') {
      autocompleteEl?.dispatchEvent(
        new CustomEvent(Events.AUTOCOMPLETE_SET_DATA, {
          detail: eventData,
        })
      )
    }

    calendar.input.removeEventListener('calendar.close', fn)
  }

  calendar.input.addEventListener('calendar.close', fn)
}

const openCalendar = (el: HTMLElement): void => {
  const calendar = ElementPropertiesManager.getProperty<SearchCalendarType>(document.getElementById(calendarId) as HTMLInputElement, 'calendar')

  if (!calendar) {
    return
  }

  if (!common.isMobile()) {
    calendar.open()

    setAutocompleteProductId(el.getAttribute('data-id'), el.getAttribute('data-name'))
  }

  if (common.isMobile() || common.isTablet()) {
    const sidePanelEl = document.getElementById('search-filters-side-panel')

    if (!sidePanelEl) {
      return
    }

    const sidePanel = ElementPropertiesManager.getProperty<SidePanel>(sidePanelEl, 'sidePanel')

    if (!sidePanel) {
      return
    }

    sidePanel.open()

    const tab = sidePanel.element ? <HTMLInputElement>sidePanel.element.querySelector('.tab__item[data-target="search"]') : null
    if (tab) {
      tab.click()
    }
    calendar.open()

    setAutocompleteProductId(el.getAttribute('data-id'), el.getAttribute('data-name'))
  }
}

export default function (): void {
  document.querySelectorAll<HTMLElement>('.results .search-result').forEach((el) => {
    const productCardEl = el.querySelector<HTMLElement>('.product-card')

    if (!productCardEl) {
      return
    }

    const linkEl = productCardEl.querySelector<HTMLLinkElement>('.product-card__link')

    SearchResult(
      el,
      {
        onOpenLink: (card) => {
          const event = new CustomEvent('productPageLink.opened', { detail: { productId: card.getAttribute('data-id') as string } })
          window.dispatchEvent(event)
        },
        onClickViewBtn: (card) => {
          if (card.hasAttribute('data-with-bestoffer')) {
            if (!linkEl) {
              return
            }

            linkEl.click()
          } else {
            openCalendar(card)
          }
        },
        onClickViewOnMapBtn: (card) => {
          openMap(card.getAttribute('data-id') as string)
        },
        pluginsConfig: {
          favorite: {
            addedSnackbar: document.getElementById('favorite_snackbar_added') as HTMLElement,
            addFailedSnackbar: document.getElementById('favorite_snackbar_add_failed') as HTMLElement,
            removedSnackbar: document.getElementById('favorite_snackbar_removed') as HTMLElement,
          },
        },
      },
      {
        onClickCard: () => {
          if (!linkEl) {
            return
          }

          linkEl.click()
        },
        onClickChangeDateBtn: (card) => {
          openCalendar(card)
        },
      }
    )
  })
}
