'use strict'

import Modal from '@ui/Modal/component'
import SearchMap, { MAP_MODE, QueryResponse } from 'assets/core/js/module/searchMap'
import SliderManager from 'assets/core/js/module/sliderManager'
import ProductCard from '@ui/Product/Card/component'
import mapLoader from 'assets/themes/campings/js/module/mapLoader'
import { isMobile, initLazyLoading } from 'assets/core/js/common'
import ElementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'

import type { Calendar as SearchCalendarType } from '@ui/Search/Calendar/component'
import type { ModalType } from '@ui/Modal/component'
import type Swiper from 'swiper'

export interface MapConfig {
  callbacks?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key in (typeof callbacksAvailable)[number]]?: ((this: void, map: SearchMapModal, ...args: any[]) => void)[]
  }
}

const callbacksAvailable = ['onInit', 'onLoad', 'onQuery', 'onMove', 'onMarkerClick', 'onModalOpen', 'onModalClose'] as const

export default class SearchMapModal {
  element!: HTMLElement
  config!: MapConfig
  map!: SearchMap | null
  modal!: ModalType | null
  isInit!: boolean
  isMapAvailable!: boolean
  refreshMapOnMove!: boolean
  slider!: Swiper
  sliderEl!: HTMLElement | null
  init!: () => void

  constructor(element: string | HTMLElement, userConfig?: MapConfig) {
    this.element = this.resolveElement(element)

    if (!this.element?.hasAttribute('data-map-boundaries')) {
      return
    }

    const sliderEl = document.getElementById('map-products-slider')

    if (!sliderEl) {
      return
    }

    this.config = userConfig ?? {}
    this.sliderEl = sliderEl
    this.isInit = false
    this.isMapAvailable = false
    this.refreshMapOnMove = true

    this.initConfig()

    ElementPropertiesManager.addProperty<SearchMapModal>(this.element, 'searchMapModal', this)
  }

  private initConfig(): void {
    if (typeof this.config.callbacks === 'undefined') {
      this.config.callbacks = {}
    }

    callbacksAvailable.forEach((cb) => {
      if (typeof this.config.callbacks?.[cb] === 'undefined') {
        // @ts-ignore
        this.config.callbacks[cb] = []
      }
    })
  }

  private resolveElement<T extends HTMLElement>(element: string | HTMLElement): T {
    let el: HTMLElement | string | null = element

    if (typeof element === 'string') {
      el = document.querySelector<T>(element)
    }

    if (!el || typeof el === 'string') {
      throw new Error('Missing element.')
    }

    return el as T
  }

  initModal(): ModalType | undefined {
    const modalMapEl = document.getElementById('search-map-modal')

    if (!modalMapEl) {
      return
    }

    this.modal = Modal(modalMapEl, {
      onOpen: () => {
        mapLoader()
          .then(() => {
            if (this.isMapAvailable === false) {
              this.initMap()
            }

            if (this.map) {
              this.map.resize()
            }
          })
          .catch(() => true)

        if (this.config.callbacks?.onModalOpen) {
          this.config.callbacks.onModalOpen.forEach((cbFn) => {
            cbFn(this)
          })
        }
      },
      onClose: () => {
        if (this.config.callbacks?.onModalClose) {
          this.config.callbacks.onModalClose.forEach((cbFn) => {
            cbFn(this)
          })
        }
      },
    })

    return this.modal
  }

  initMap(): void {
    const calendar = ElementPropertiesManager.getProperty<SearchCalendarType>(
      document.getElementById('search_offers_checkInDate') as HTMLInputElement,
      'calendar'
    )
    const checkInDateValue = calendar?.getDate()
    const modalTitleEl = this.modal?.getElement().querySelector<HTMLElement>('.o-modal__heading-title')

    if (this.config.callbacks?.onInit) {
      this.config.callbacks.onInit.forEach((cbFn) => {
        cbFn(this)
      })
    }

    this.initSlider()
    this.initToggleQueryElement()
    this.toggleLoader('map', 'visible')

    this.map = new SearchMap(
      this.element,
      {
        mode: 'query',
        onLoad: () => {
          this.toggleLoader('map', 'hidden')
          this.isMapAvailable = true

          if (this.config.callbacks?.onLoad) {
            this.config.callbacks.onLoad.forEach((cbFn) => {
              cbFn(this)
            })
          }
        },
        onQuery: (response: QueryResponse) => {
          this.updateSlider(response.tiles)

          this.toggleLoader('move', 'hidden')

          if (this.config.callbacks?.onQuery) {
            this.config.callbacks.onQuery.forEach((cbFn) => {
              cbFn(this, response)
            })
          }
        },
        onMove: () => {
          this.toggleLoader('move', 'visible')

          if (this.modal?.getElement().hasAttribute('data-move-title') && modalTitleEl) {
            modalTitleEl.innerHTML = this.modal.getElement().getAttribute('data-move-title') as string

            this.modal.getElement().removeAttribute('data-move-title')
          }

          if (this.config.callbacks?.onMove) {
            this.config.callbacks.onMove.forEach((cbFn) => {
              cbFn(this)
            })
          }
        },
        markerProperties: {
          offset: {
            bottom: [0, -55],
          },
          showIcon: (marker) => !marker.overlayText && checkInDateValue === null,
          getContent: (markerId) => {
            const sliderWrapper = this.sliderEl?.querySelector('.swiper-wrapper')

            if (!sliderWrapper) {
              return
            }

            const cardEl = sliderWrapper.querySelector<HTMLElement>('.product-card[data-id="' + markerId + '"]')?.cloneNode(true) as HTMLElement

            if (!cardEl) {
              return
            }

            ProductCard(cardEl)

            return cardEl
          },
          onClick: (markerId) => {
            if (this.config.callbacks?.onMarkerClick) {
              this.config.callbacks.onMarkerClick.forEach((cbFn) => {
                cbFn(this, markerId)
              })
            }

            // @ts-ignore
            if (!isMobile()) {
              return true
            }

            const slideEl = this.sliderEl?.querySelector('.swiper-slide[data-id="' + markerId + '"]:not(.swiper-slide-duplicate)')

            if (!slideEl) {
              return
            }

            this.slider.slideTo(parseInt(slideEl?.getAttribute('data-index') as string, 10))

            return false
          },
        },
      },
      []
    )
  }

  private toggleLoader(type: 'map' | 'move', status: 'visible' | 'hidden'): void {
    const el = document.getElementById('search-map-loader-block')

    if (!el || !this.refreshMapOnMove) {
      return
    }

    el.setAttribute('data-loader-type', type)

    if (status === 'hidden') {
      el.setAttribute('hidden', 'hidden')
    } else if (status === 'visible') {
      el.removeAttribute('hidden')
    }
  }

  private initToggleQueryElement(): void {
    const el = document.getElementById('search-map-toggle-query')

    if (!el) {
      return
    }

    el.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement

      this.map?.setMapMode(target.checked ? MAP_MODE.QUERY : MAP_MODE.DEFAULT)
      this.refreshMapOnMove = target.checked
    })
  }

  private updateSlider(tiles: QueryResponse['tiles']): void {
    const sliderWrapper = this.sliderEl?.querySelector('.swiper-wrapper')

    if (!sliderWrapper) {
      return
    }

    sliderWrapper.innerHTML = ''

    this.slider.update()

    Object.keys(tiles).forEach(function (tileId, i) {
      const swiperSlideEl = document.createElement('div')
      swiperSlideEl.className = 'swiper-slide'
      swiperSlideEl.setAttribute('data-id', tileId)
      swiperSlideEl.setAttribute('data-index', i.toString())
      swiperSlideEl.innerHTML = tiles[tileId] as string

      sliderWrapper?.appendChild(swiperSlideEl)
    })

    this.slider.update()

    initLazyLoading(sliderWrapper.querySelectorAll('.lazy'))
  }

  private initSlider(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this

    if (!this.sliderEl) {
      return
    }

    const sliderId = SliderManager.initSlider(
      this.sliderEl,
      {
        navigation: {
          nextEl: '.swiper-pictures-button-next',
          prevEl: '.swiper-pictures-button-prev',
        },
        grabCursor: true,
        spaceBetween: 10,
        centeredSlides: true,
        speed: 1000,
        slidesPerView: 'auto',
        on: {
          init: () => {
            initLazyLoading(this.sliderEl?.querySelectorAll('.lazy'))
          },
          slideChangeTransitionStart: function (this: { slides: Record<string, HTMLElement>; activeIndex: number }) {
            if (self.map) {
              self.map.openMarker(this.slides[this.activeIndex]?.getAttribute('data-id') as string)
            }
          },
          click: function (this: { slides: Record<string, HTMLElement>; activeIndex: number }) {
            const url = this.slides[this.activeIndex]?.getAttribute('data-url')

            if (url) {
              window.location.href = url
            }
          },
        },
      },
      false
    )

    if (!sliderId) {
      return
    }

    const slider = ElementPropertiesManager.getProperty<Swiper>(this.sliderEl, 'slider')

    if (!slider) {
      return
    }

    this.slider = slider
  }
}
